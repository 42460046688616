import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ServicesSection.css'; 

const ServicesSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const services = [
    {
      title: "AI Strategy Overview",
      icon: "tim-icons icon-compass-05",
      description: "A comprehensive guide to understanding and implementing AI strategies with HAI Consulting.",
      longDescription: "The AI Strategy Overview outlines the key components of HAI Consulting's approach to AI, including the management of day-to-day operations, the development and implementation of tailored AI solutions, and the guidance in ethical AI practices. This overview details the strategic vision for AI, highlighting innovation, quality assurance methods, client project delivery, and the importance of continuous learning within the team."
      },
      {
        title: "AI Training and Workshops",
        icon: "tim-icons icon-laptop",
        description: "Upskill your team on AI fundamentals, applications, and best practices for adoption.",
        longDescription: "Our AI Training and Workshops service offers tailored programs for executives, managers, and technical teams. We cover AI fundamentals, industry-specific applications, and hands-on tool tutorials. Our workshops on AI strategy, governance, and ethical considerations empower your leaders to make informed decisions. We also provide guidance on fostering an AI-ready culture and managing organizational change for smooth AI adoption.",
      },
      {
        "title": "AI Implementation and Integration",
        "icon": "tim-icons icon-spaceship",
        "description": "Seamless integration of AI solutions into existing business processes.",
        "longDescription": "Our AI Implementation and Integration service offers comprehensive support for bringing AI solutions from concept to reality within your organization. We start with an in-depth needs assessment to understand your unique business requirements and existing systems. Our team works collaboratively with your stakeholders to design, develop, and deploy AI models tailored for optimal integration. This includes rigorous testing, validation, and user training to ensure a smooth transition. We focus on enhancing operational efficiency, driving innovation, and providing ongoing support to maximize the value of your AI investments."
    },
    {
        title: "AI Compliance and Risk Management",
        icon: "tim-icons icon-lock-circle",
        description: "Ensure compliance with AI regulations and manage associated risks.",
        longDescription: "Our AI Compliance and Risk Management service assists businesses in understanding and adhering to the evolving landscape of AI regulations. We conduct comprehensive audits of AI systems to identify risks and provide actionable recommendations. This service ensures that your AI initiatives are compliant with legal and ethical standards, minimizing potential liabilities.",
    },
    {
        title: "HAI Driven Transformation",
        icon: "tim-icons icon-refresh-02",
        description: "Support organizations in managing AI-driven transformation and change.",
        longDescription: "Our AI Change Management service focuses on helping organizations navigate the complexities of integrating AI into their operations. We provide strategies for effective communication, stakeholder engagement, and cultural shifts necessary for successful AI adoption. Through workshops and resources, we prepare your teams to embrace change and leverage AI for enhanced productivity.",
    },
    {
      title: "Ongoing AI Support and Optimization",
      icon: "tim-icons icon-link-72",
      description: "Continuous monitoring, maintenance, and improvement of AI systems post-implementation.",
      longDescription: "Our Ongoing AI Support and Optimization service ensures the long-term success of your AI initiatives. We provide post-implementation support, troubleshooting, and regular performance monitoring of your AI systems. Our team conducts AI model retraining and updates to maintain accuracy and relevance. We also offer advisory support for scaling your AI initiatives and exploring new use cases, ensuring your AI solutions continue to deliver value as your business evolves.",
    },

  ];

  const handleCardClick = (service) => {
    setSelectedService(service);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const scrollToContact = () => {
    document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
    setShowModal(false);
  };

  return (
    <section className="section section-lg" id="services">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <h1 className="text-center fade-in">Our Services</h1>
            <div className="row row-grid justify-content-center">
              {services.map((service, index) => (
                <div className="col-lg-4 fade-in" key={index}>
                  <div className="card hover-card" onClick={() => handleCardClick(service)}>
                    <div className="card-body">
                      <div className="icon icon-primary">
                        <i className={service.icon}></i>
                      </div>
                      <h4 className="card-title">{service.title}</h4>
                      <p className="card-text">{service.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>{selectedService?.title}</Modal.Title>
          <Button style={{marginTop: "-7%", marginRight: "-5%", fontSize: "150%"}}className="custom-close-button" variant="link" onClick={handleCloseModal}>&times;</Button>
        </Modal.Header>
        <Modal.Body>
          <p>{selectedService?.longDescription}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={scrollToContact}>
            Contact Us
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default ServicesSection;