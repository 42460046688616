import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './BlogStyles.css';

const BlogPreview = () => {
  const [recentPost, setRecentPost] = useState(null);

  useEffect(() => {
    const fetchRecentPost = async () => {
      try {
        const response = await fetch('/blogPosts.json');
        const data = await response.json();
        
        if (data.posts.length > 0) {
          setRecentPost(data.posts[0]);
        }
      } catch (error) {
        console.error('Error fetching recent post:', error);
      }
    };

    fetchRecentPost();
  }, []);

  if (!recentPost) return null;

  const handleReadMore = () => {
    window.open(`/blogs/${recentPost.fileName}`, '_blank');
  };

  return (
    <section className="section section-lg text-white py-5" id="blog">
      <div className="container">
        <h2 className="title text-center mb-5">Latest from Our Blog</h2>
        <div className="row">
          <div className="col-md-12 mb-4">
            <div className="card blog-card h-100">
              <div className="card-body">
                <h5 className="card-title">{recentPost.title}</h5>
                <p className="card-text">{recentPost.description}</p>
                <p className="card-text"><small className="text-muted">Published on {recentPost.date}</small></p>
              </div>
              <div className="card-footer bg-transparent border-0">
                <button onClick={handleReadMore} className="btn btn-primary">Read More</button>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-4">
          <Link to="/blog" className="btn btn-outline-light">See All Posts</Link>
        </div>
      </div>
    </section>
  );
};

export default BlogPreview;