import React from 'react';
import './AboutSection.css';

const AboutSection = () => {
  return (
    <section className="section section-lg" id="about">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 justify-content-center">
            <h1 className="text-center fade-in about-title">About Us</h1>
            <p style={{width: "80%", paddingLeft: "10%"}} className="text-center fade-in about-text">
              At HAI Consulting, we believe that AI has the potential to revolutionize the way businesses operate. Our mission is to demystify AI, making its powerful capabilities accessible to organizations of all sizes. We focus on a human-centered approach, ensuring that our AI solutions enhance human capabilities rather than replace them. We are committed to fostering innovative and ethical AI practices that drive sustainable growth for our clients.
              <br /><br />
              Our services encompass a comprehensive suite of offerings, including AI strategy development, implementation support, training workshops, and ongoing optimization. We tailor our solutions to meet the specific needs and constraints of small business, providing personalized attention and expert guidance every step of the way. Our team of seasoned AI specialists leverages cutting-edge technologies and industry best practices to ensure your business stays ahead of the curve in an increasingly AI-driven marketplace.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;