import React from 'react';
import './ToolsSection.css';
import genvoicePreview from '../assets/img/tools/genvoice-preview.png'; // This will be your screenshot

const ToolsSection = () => {
  return (
    <section id="tools" className="section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <h2 className="mb-4">Our Tools</h2>
            <p className="text-muted mb-5">
              Explore our suite of AI-powered tools designed to enhance your business operations.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 mb-4">
            <div className="tool-card">
              <div className="tool-preview">
                <a href="https://haiconsulting.github.io/genvoice-live" 
                   target="_blank" 
                   rel="noopener noreferrer">
                  <img 
                    src={genvoicePreview} 
                    alt="GenVoice Live Preview" 
                    className="img-fluid"
                  />
                </a>
              </div>
              <div className="tool-info">
                <h3>GenVoice</h3>
                <p>
                  A Quick & Easy Invoice generation tool for small business owners. Also can create statements of work with hourly rates, and has a Gen-AI template generation component. 
                </p>
                <a 
                  href="https://haiconsulting.github.io/genvoice-live" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="btn btn-primary"
                >
                  Try it now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ToolsSection; 